import { useOutletContext as remixUseOutletContext } from '@remix-run/react'

import type { CurrentCustomer, CurrentUser } from '~/root'
import type { AuthProfile } from '~/services/auth-n'
import type { FeatureFlags } from '~/services/feature-flag'
import type { FlashMessage } from '~/services/session'

export type RootContext = {
  auth: AuthProfile | null
  isSiteAdmin: boolean
  currentUser: CurrentUser | null
  currentUserPermissions: string[]
  message: FlashMessage
  uptime: number
  impersonatedUser: CurrentUser | null
  impersonationUntil: Date | null
  impersonatedUserPermissions: string[]
  customer: CurrentCustomer | null
  hasMultipleAccounts: boolean
  timezone: {
    name: string
    isFallback: boolean
  }
  emailUsage?: {
    creditsUsed: number
    allowedPerMonth: number
    allowedPerCampaign: number
    remaining: number
    remainingForCampaign: number
  }
  clientEnv: ClientEnv
  featureFlags: FeatureFlags
}

export const useRootContext = () => remixUseOutletContext<RootContext>()
